<template>
  <div class="authentication">
    <h1>Login In Or Sign Up Page</h1>      
  </div>
</template>

<script>


export default {
  name: 'Auth',
  components: {
    
  }
}
</script>
